<template>
  <b-container fluid v-if="!globalLoad">

    <!-- ===============COMERCIAL  =============== -->
    <template  v-if="Object.keys(currentService).length > 0 && (currentService.own === true || currentService.own === 1)">
      <h5 class="text-secondary text-left font-weight-bold mt-3">Administración Comercial de Transporte</h5>
      <hr class="bg-primary">
      <div class="row justify-content-center mb-4" v-if="!LOCK_SERVICE">
        <div class="col-12 text-right">
          <b-button @click="OpenComercialModal()" pill  variant="primary" >
            <b-icon-plus/> Nuevo
          </b-button>
        </div>
      </div>
      <!-- ===============COMERCIAL  =============== -->
      <!--Modal Comercial  -->
      <b-modal title="Detalle del Servicio Comercial" ref="comercial-modal" size="xl" centered>
        <a-form-model layout="horizontal" :model="ComercialForm" :rules="ComercialRules" ref="comercialForm">
          <b-container fluid>
            <a-row>
              <a-col :sm="{span:20}" :md="{span:18}" :lg="{span:16}">
                <a-form-model-item ref="logistics_shipment_type_id" label="Tipo Logístico de Unidad" prop="logistics_shipment_type_id" >
                  <a-select v-model="ComercialForm.logistics_shipment_type_id"
                            placeholder="Tipo Logístico de Unidad"
                            show-search :filter-option="filterOption">
                    <a-select-option  v-for="option in logisticsShipmentTypesCatalog" :key="option.jsonApi.id" :value="option.jsonApi.id">
                      {{option.code}} - {{option.name}}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :sm="{span:3, offset:1}" :md="{span:5, offset:1}" :lg="{span:6, offset:2}">
                <a-form-model-item ref="capacity" label="Capacidad Total (Toneladas)" prop="capacity" >
                  <a-input-number size="large" :min="1" :max="100000" v-model="ComercialForm.capacity" />
                </a-form-model-item>
              </a-col>
            </a-row>
            <!-- OTROS CAMPOS -->
            <!-- BOTONES -->
            <my-spinner :load="load"/>
            <a-row align="middle" justify="end" class="mt-4" v-show="!load">
              <a-col :span="3" offset="21" v-if="!LOCK_SERVICE">
                <responsive-button class="mr-1" variant="primary" pill block
                                   text="Guardar" responsive="md" icon="archive-fill"
                                   @ClickEvent="SendComercial"/>
              </a-col>
            </a-row>
          </b-container>
        </a-form-model>
        <template v-slot:modal-footer>
          <simple-modal-footer @ClickEvent="CloseComercialModal" />
        </template>
      </b-modal>
      <!--Modal Comercial  -->
      <!--Tabla Comercial  -->
      <a-table
        size="small"
        :scroll="{x:1300}"
        bordered
        :dataSource="tableComercial.data"
        :columns="comercialCols"
        :loading="tableComercial.loading"
        :row-key="record => record.code"
        :pagination="tableComercial.pagination"
        @change="GetComercial"
      >
        <!-- Columnas Personalizadas -->
        <div slot="logisticsShipmentTypes" slot-scope="logisticsShipmentTypes">
          <strong>{{logisticsShipmentTypes.name}}</strong><br>
          <strong>{{logisticsShipmentTypes.trailers}}</strong> &nbsp; <small>Trailers</small>
        </div>
        <div slot="action" slot-scope="data">
          <b-button-group vertical>
            <responsive-button variant="secondary" class="mb-1"
                               text="Editar" icon="pencil-square" pill size="sm" responsive="md"
                               @ClickEvent="OpenComercialModal(data)"
            />
          </b-button-group>
        </div>
        <!-- Columnas Personalizadas -->
      </a-table>
      <!--Tabla Comercial  -->
    </template>
    <!-- ===============LOGISTICA  =============== -->
    <h5 class="text-secondary text-left font-weight-bold mt-3">Administración Logística de Transporte</h5>
    <hr class="bg-primary">
    <!--Botonera  -->
    <div v-if="!LOCK_SERVICE" class="row justify-content-center mb-4">
      <div class="col-12 text-right">
        <b-button @click="OpenModal()" pill  variant="primary" >
          <b-icon-plus/> Nuevo
        </b-button>
        <b-button @click="GetCarriers()" pill  variant="secondary" >
          <b-icon-search/> Actualizar
        </b-button>
      </div>
    </div>
    <!--Botonera  -->
    <!--Modal Logistica  -->
    <b-modal title="Detalle del Servicio Logístico" ref="carrier-form" size="xl" centered @hide="CloseModal">
      <a-steps :current="current">
        <a-step v-for="item in steps" :key="item.title" :title="item.title" />
      </a-steps>
      <div class="steps-content">
        {{ steps[current].content }}
        <template v-if="steps[current].key === 'truck' ">
          <a-row align="middle" justify="start" class="mb-4">
            <!--TRUCKS COL-->
            <a-col :span="24"  >
              <div class="card">
                <div class="card-body">
                  <truckForm typeTable="select" v-if="getTruckID === 0"/>
                  <p v-else> Ya ha seleccionado una unidad de transporte, para cambiarla elimine la unidad actual desde el Resumen del Servicio de Transporte mostrado en la parte inferior</p>
                </div>
              </div>
            </a-col>
            <!--TRUCKS COL-->
          </a-row>
        </template>
        <template v-else-if="steps[current].key === 'driver' ">
          <a-row align="middle" justify="start" class="mb-4">
            <!--DRIVERS COL-->
            <a-col :span="24">
              <div class="card">
                <div class="card-body">
                  <driverForm  typeTable="select"  v-if="getDriverID === 0 && getTruckID !== 0"/>
                  <p v-else> Ya ha seleccionado un conductor, para cambiarlo elimine el conductor actual desde el Resumen del Servicio de Transporte mostrado en la parte inferior</p>
                </div>
              </div>
            </a-col>
            <!--DRIVERS COL-->
          </a-row>
        </template>
        <template v-else-if="steps[current].key === 'details' ">
          <a-form-model layout="horizontal" :model="servicePlanningsCarriersForm" :rules="servicePlanningsCarriersRules" ref="rule-Form">
            <b-container fluid>
              <!-- OTROS CAMPOS -->
              <b-container fluid>
                <p> De ser necesario registre el Número de Autorización SEMARNAT y el Registro SCT que correspondan al manifiesto </p>
                <hr class="bg-primary">
                <a-row align="middle" justify="center" class="mb-4">
                  <div class="card">
                    <div class="card-body">
                      <a-form-model-item label="No.Autorización SEMARNAT"
                                         ref="environmental_record" prop="environmental_record"
                                         :rules="{ required: true, message: 'Ingrese el No.Autorización SEMARNAT', trigger: 'blur'}"
                      >
                        <a-input v-model="servicePlanningsCarriersForm.environmental_record"
                                 placeholder="No.Autorización SEMARNAT"
                        />

                      </a-form-model-item>

                      <a-form-model-item label="Registro SCT"
                                         ref="sct_record" prop="sct_record"
                                         :rules="{ required: true, message: 'Ingrese el Registro SCT', trigger: 'blur'}"
                      >
                        <a-input v-model="servicePlanningsCarriersForm.sct_record" placeholder="Registro SCT"
                        />
                      </a-form-model-item>
                    </div>
                  </div>
                </a-row>

                <template v-if="getTruckID.logisticsShipmentTypes.trailers !== 0">
                  <p v-if="getTruckID !== 0">
                    Debe registrar {{getTruckID.logisticsShipmentTypes.trailers}} placa(s) correspondientes a los acoplados de la unidad
                  </p>
                  <hr class="bg-primary">
                  <a-row align="middle" justify="center" class="mb-4">
                    <div class="card">
                      <div class="card-body">
                        <a-form-model-item
                          v-for="(tag, index) in servicePlanningsCarriersForm.coupled_tags"
                          :key="tag.key"
                          :label="index === 0 ? 'Placas de Acoplados' : ''"
                          ref="coupled_tags"
                          :prop="'coupled_tags.' + index + '.value'"
                          :rules="{ required: true, message: 'La placa no puede estar vacía', trigger: 'blur'}"
                        >
                          <a-input v-model="tag.value" placeholder="Placa de Acoplado"
                                   style="width: 60%; margin-right: 8px"
                          />
                          <!--                        <responsive-button text="" icon="dash" responsive="md" size="sm"-->
                          <!--                                           v-if="servicePlanningsCarriersForm.coupled_tags.length > 1"-->
                          <!--                                           :disabled="servicePlanningsCarriersForm.coupled_tags.length === 1"-->
                          <!--                                           @ClickEvent="removeDomain(tag)" pill/>-->
                          <responsive-button text="" icon="dash" responsive="md" size="sm"
                                             @ClickEvent="removeDomain(tag)" pill/>
                        </a-form-model-item>
                        <a-form-model-item>
                          <responsive-button text="Agregar Placa de Acoplados" icon="plus" responsive="md" @ClickEvent="addDomain" pill/>
                        </a-form-model-item>
                      </div>
                    </div>
                  </a-row>
                </template>
                <p> Especifique la Gestión Especial de Transporte  </p>
                <hr class="bg-primary">
                <a-row align="middle" justify="center" class="mb-4">
                  <div class="card">
                    <div class="card-body">
                        <a-form-model-item label="Gestión Especial de Transporte"
                                         ref="transportation_management" prop="transportation_management">

                        <a-select v-model="servicePlanningsCarriersForm.transportation_management" allowClear
                                  placeholder="Gestión Especial de Transporte">
                          <a-select-option  v-for="option in SpecialTransportation" :key="option.value" :value="option.text" >
                            {{option.text}}
                          </a-select-option>
                        </a-select>

                      </a-form-model-item>

                    </div>
                  </div>
                </a-row>
              </b-container>
              <!-- OTROS CAMPOS -->
              <!-- BOTONES -->
              <my-spinner :load="load"/>
              <a-row align="middle" justify="end" class="mt-4" v-show="!load">
                <a-col :span="3" offset="21">
                  <responsive-button class="mr-1" variant="primary" pill block
                                     text="Guardar" responsive="md" icon="archive-fill"
                                     @ClickEvent="SendCarriers"/>
                </a-col>
              </a-row>
            </b-container>
          </a-form-model>
        </template>
      </div>
      <div class="steps-action mb-4">
        <div class="row justify-content-center">
          <b-button variant="secondary" pill v-if="current > 0" @click="prev">
            <b-icon icon="arrow-left"/>
          </b-button>
          <b-button variant="primary" pill v-if="current < steps.length - 1 && getTruckID !== 0" @click="next">
            <b-icon icon="arrow-right"/>
          </b-button>
        </div>
      </div>

      <!-- TABLA DE RESUMEN  -->
      <b-table-simple hover bordered small responsive>
        <b-thead head-variant="dark">
          <b-tr>
            <b-th colspan="4" class="text-center">Resumen del Servicio de Transporte</b-th>
          </b-tr>
        </b-thead>
        <!--TRUCKS-->
        <b-thead v-if="getTruckID !== 0" head-variant="dark">
          <b-tr>
            <b-th colspan="3">Detalle de Transporte</b-th>
            <b-th class="text-center">Acciones</b-th>
          </b-tr>
        </b-thead>
        <b-tbody v-if="getTruckID !== 0">
          <b-tr>
            <b-th> <strong>Placa:</strong> {{getTruckID.tag}}</b-th>
            <b-th>
              <strong>Tipo Logístico:</strong> {{getTruckID.logisticsShipmentTypes.name}}
              <br>
              <strong>#Acoplados:</strong> {{getTruckID.logisticsShipmentTypes.trailers}}
            </b-th>
            <b-th>
              <div v-if="getTruckID.carrierCompanies.name">
                <strong>Empresa Transportista: </strong> {{getTruckID.carrierCompanies.name}}
                <br>
                <strong>Registro SCT: </strong> {{getTruckID.carrierCompanies.sct_record}}
                <br>
                <strong>Autorización SEMARNAT: </strong> {{getTruckID.carrierCompanies.environmental_record}}
              </div>
              <br>
            </b-th>
            <b-th>
              <responsive-button icon="trash" text="Eliminar" @ClickEvent="RESET_SELECTED_CATALOGS_KEY('trucks')"
                                 responsive="md" pill variant="danger" size="sm"/>
            </b-th>
          </b-tr>
        </b-tbody>
        <!--TRUCKS-->
        <!--DRIVERS-->
        <b-thead v-if="getDriverID !== 0" head-variant="dark">
          <b-tr>
            <b-th colspan="3">Detalle de Conductor</b-th>
            <b-th class="text-center">Acciones</b-th>
          </b-tr>
        </b-thead>
        <b-tbody v-if="getDriverID !== 0">
          <b-tr>
            <b-th>  <strong>Conductor:</strong> {{getDriverID.name}}</b-th>
            <b-th>
                  <span v-if="getDriverID.carrierCompanies.name">
                    <strong>Empresa Transportista:</strong>
                    {{getDriverID.carrierCompanies.name}}
                  </span>
            </b-th>
            <b-th>
            </b-th>
            <b-th>
              <responsive-button icon="trash" text="Eliminar" @ClickEvent="RESET_SELECTED_CATALOGS_KEY('drivers')"
                                 responsive="md" pill variant="danger" size="sm"/>
            </b-th>
          </b-tr>
        </b-tbody>
        <!--DRIVERS-->
        <!--coupled_tag-->
        <b-thead  head-variant="dark">
          <b-tr>
            <b-th colspan="4" class="text-center">Placas de Acoplado</b-th>
          </b-tr>
        </b-thead>
        <b-tbody >
          <b-tr v-for="tag in servicePlanningsCarriersForm.coupled_tags" :key="tag.key">
            <b-th colspan="4" class="text-center">{{ tag.value }} </b-th>
          </b-tr>
        </b-tbody>
        <b-thead  head-variant="dark">
          <b-tr>
            <b-th colspan="4" class="text-center">Gestión Especial de Transporte</b-th>
          </b-tr>
        </b-thead>
        <b-tbody >
          <b-tr>
            <b-th colspan="4" class="text-center">{{servicePlanningsCarriersForm.transportation_management}} </b-th>
          </b-tr>
        </b-tbody>
        <!--coupled_tag-->
      </b-table-simple>
      <!-- TABLA DE RESUMEN  -->

      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal" />
      </template>
    </b-modal>
    <!--Modal Logistica  -->
    <!--Tabla Logistica  -->
    <servicePlanningCarrierTable :serviceId="servicePlanningId" :tableObject="tableCarrier"
                                 @EditModal="OpenModal" @DeleteCarrier="DeleteCarrier"/>
    <!--Tabla Logistica  -->
    <!-- ===============LOGISTICA  =============== -->
  </b-container>
  <b-container v-else>
    <my-spinner :load="globalLoad"/>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Modal, notification } from 'ant-design-vue'
import { transportServiceFormMixin } from '@/mixins/serviceFormMixins/transportServiceFormMixin'
import { getGeneralMixin } from '@/mixins/getGeneralMixin'
import { catalogMixin } from '@/mixins/catalogMixin'
import truckForm from '@/views/geocycle/catalogs/trucks/components/transportForm'
import driverForm from '@/views/geocycle/catalogs/drivers/components/transportForm'
import servicePlanningCarrierTable from '../../components/servicePlanningCarrierTable'

export default {
  name: 'transportServiceForm',
  components: {
    truckForm,
    driverForm,
    servicePlanningCarrierTable,
  },
  props: {
    folio: {
      default: '',
      type: [String],
      required: false,
    },
    serviceId: {
      default: '0',
      type: [String],
      required: false,
    },
    infoView: {
      default: false,
      type: [Boolean],
    },
  },
  mixins: [
    transportServiceFormMixin,
    getGeneralMixin,
    catalogMixin,
  ],
  data() {
    return {
      // Steps
      current: 0,
      steps: [
        {
          title: 'Selección de Transporte',
          key: 'truck',
        },
        {
          title: 'Selección de Conductor',
          key: 'driver',
        },
        {
          title: 'Detalles',
          key: 'details',
        },
      ],
      // Variable para saber si el modal se abre para crear o actualizar y con base en esto escribir o no SEMARNAT y SCT
      logModalState: 'store',
      // Steps
      // <!-- LOG -->
      tableCarrierCompanyLoading: false,
      // service_plannings_carriers form
      servicePlanningCarrierId: '',
      servicePlanningsCarriersForm: {
        coupled_tags: [],
        truck_id: undefined,
        driver_id: undefined,
        service_planning_id: undefined,
        environmental_record: '',
        sct_record: '',
        transportation_management: undefined,
      },
      servicePlanningsCarriersRules: {
        driver_id: [
          { required: true, message: 'Debe seleccionar un conductor.', trigger: 'blur' },
        ],
        truck_id: [
          { required: true, message: 'Debe seleccionar un camión.', trigger: 'blur' },
        ],
        service_planning_id: [
          { required: true, message: 'Servicio inválido.', trigger: 'blur' },
        ],
        coupled_tag: [
          {
            validator: () => {
              if (this.servicePlanningsCarriersForm.coupled_tags.length === this.getTruckID.logisticsShipmentTypes.trailers) {
                return true
              } else {
                return false
              }
            },
            message: 'Debe agregar las placas equivalentes al número total de acoplados.',
            trigger: 'blur',
          },
        ],
        // sct_record: [{ required: true, message: 'Ingrese el Registro SCT', trigger: 'blur' }],
        // environmental_record: [{ required: true, message: 'Ingrese el No.Autorización SEMARNAT', trigger: 'blur' }],
      },
      SpecialTransportation: [
        { value: 'M0', text: 'Liner' },
        { value: 'M1', text: 'Maniobra' },
        { value: 'M2', text: 'Peligroso' },
        { value: 'M3', text: 'Liner + Maniobra' },
        { value: 'M4', text: 'Liner + Peligroso' },
        { value: 'M5', text: 'Liner + Maniobra + Peligroso' },
        { value: 'M6', text: 'Maniobra + Peligroso' },
        { value: 'M7', text: 'Tarima' },
      ],
      // service_plannings_carriers form
      load: false,
      tableCarrier: {
        data: [],
        loading: false,
        perPageValue: 15,
        currentPageValue: 1,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 30,
          lastPage: 0,
        },
      },
      // <!-- LOG -->
      // <!-- Comercial -->
      tableComercial: {
        data: [],
        loading: false,
        perPageValue: 15,
        currentPageValue: 1,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 30,
          lastPage: 0,
        },
      },
      comercialCols: [
        {
          title: 'Tipo Logístico de Transporte',
          dataIndex: 'logisticsShipmentTypes',
          key: 'logisticsShipmentTypes',
          width: '200px',
          scopedSlots: {
            customRender: 'logisticsShipmentTypes',
          },
        },
        {
          title: 'Capacidad (Toneladas)',
          dataIndex: 'capacity',
          key: 'capacity',
          width: '150px',
          class: 'text-center',
          scopedSlots: {
            customRender: 'capacity',
          },
        },
        {
          title: 'Acción',
          dataIndex: '',
          key: 'x',
          width: '150px',
          class: 'text-center',
          scopedSlots: {
            customRender: 'action',
          },
        }],
      comercialID: '0',
      ComercialForm: {
        service_planning_id: undefined,
        logistics_shipment_type_id: undefined,
        capacity: 0,
      },
      ComercialRules: {
        logistics_shipment_type_id: [
          { required: true, message: 'Debe seleccionar el tipo logístico de unidad.', trigger: 'blur' },
        ],
        service_planning_id: [
          { required: true, message: 'Servicio inválido.', trigger: 'blur' },
        ],
        capacity: [
          { required: true, message: 'Ingrese la capacidad de la unidad.', trigger: 'blur' },
        ],
      },
      logisticsShipmentTypesCatalog: [],
      // <!-- Comercial -->
      globalLoad: false,
    }
  },
  computed: {
    ...mapGetters('servicePlannings', ['GET_SELECTED_CATALOGS_KEY', 'state', 'GET_SCT_OF_SELECTED_TRUCK', 'GET_SEMARNAT_OF_SELECTED_TRUCK', 'LOCK_SERVICE']),
    getDriverID() {
      return this.$store.state.servicePlannings.selectedCatalogs.drivers
    },
    getTruckID() {
      return this.$store.state.servicePlannings.selectedCatalogs.trucks
    },
    sapProgrammingCode() {
      return this.$store.state.servicePlannings.sapProgrammingCode
    },
    servicePlanningId() {
      return this.$store.state.servicePlannings.servicePlanningId
    },
    sortDirection() {
      if (this.sortDirectionValue === '') {
        return ''
      } else if (this.sortDirectionValue === '-') {
        return '-'
      } else {
        return ''
      }
    },
    sortValue() {
      if (this.sortByValue === '') {
        return this.sortByDefaultValue
      } else {
        return this.sortByValue
      }
    },
    currentService() {
      return this.$store.state.servicePlannings.currentService
    },
  },
  methods: {
    ...mapActions('servicePlannings', ['RESET_SELECTED_CATALOGS_KEY', 'RESET_SELECTED_CATALOGS', 'SET_SELECTED_CATALOGS']),
    // ServicePlanningCarriers
    async GetCarriers(paginationEvent = {}) {
      const params = {
        'page[number]': (Object.keys(paginationEvent).length !== 0) ? `${this.tableCarrier.pagination.current}` : `${this.tableCarrier.currentPageValue}`,
        'page[size]': `${this.tableCarrier.perPageValue}`,
        'filter[service]': this.servicePlanningId,
        include: 'drivers,trucks.logisticsShipmentTypes,trucks.carrierCompanies,weightRegistries,checkListForms.checks',
      }
      await this.getGeneralAntTable(paginationEvent, 'servicePlanningsCarriers', params, this.tableCarrier)
    },
    OpenModal(data = {}) {
      if (Object.keys(data).length > 0) {
        // Update
        // Establece el estadoi del modal
        this.logModalState = 'edit'
        // Inicializa el wizard en la etapa final
        this.current = 2
        // Set drivers & truck ID
        this.servicePlanningCarrierId = data.jsonApi.id
        this.SET_SELECTED_CATALOGS({
          resource: 'drivers',
          data: data.drivers,
        })
        this.SET_SELECTED_CATALOGS({
          resource: 'trucks',
          data: data.trucks,
        })
        // set form
        this.servicePlanningsCarriersForm.driver_id = this.getDriverID.jsonApi.id
        this.servicePlanningsCarriersForm.truck_id = this.getTruckID.jsonApi.id
        this.servicePlanningsCarriersForm.service_planning_id = this.servicePlanningId
        this.servicePlanningsCarriersForm.sct_record = data.sct_record
        this.servicePlanningsCarriersForm.environmental_record = data.environmental_record
        this.servicePlanningsCarriersForm.transportation_management = data.transportation_management
        // Set placas de acoplados
        const coupleTag = []
        if (data.coupled_tag !== null && data.coupled_tag.length > 0) {
          data.coupled_tag.forEach((element, index) => {
            coupleTag.push({
              value: element,
              key: index,
            })
          })
        }
        this.servicePlanningsCarriersForm.coupled_tags = coupleTag
      }
      this.$refs['carrier-form'].show()
    },
    CloseModal() {
      this.$refs['carrier-form'].hide()
      // Reset vuex ids
      this.RESET_SELECTED_CATALOGS()
      // Reset Form
      this.resetCarrierForm()
      this.current = 0
    },
    SendCarriers() {
      this.$refs['rule-Form'].validate(valid => {
        if (valid) {
          // Validar placas
          if (this.servicePlanningsCarriersForm.coupled_tags.length !== this.getTruckID.logisticsShipmentTypes.trailers) {
            // Mostrar modal de error
            Modal.error({
              title: '¡Ha ocurrido un error!',
              content: 'Debe agregar las placas equivalentes al número total de acoplados.',
              zIndex: 3000,
            })
          } else {
            // Asignar ids desde vuex
            this.servicePlanningsCarriersForm.driver_id = this.getDriverID.jsonApi.id
            this.servicePlanningsCarriersForm.truck_id = this.getTruckID.jsonApi.id
            this.servicePlanningsCarriersForm.service_planning_id = this.servicePlanningId
            const coupleTag = []
            this.servicePlanningsCarriersForm.coupled_tags.forEach(element => {
              coupleTag.push(element.value)
            })

            const relationships = {
              servicePlannings: {
                data: { id: this.servicePlanningId, type: 'servicePlannings' },
              },
              trucks: {
                data: { id: this.servicePlanningsCarriersForm.truck_id, type: 'trucks' },
              },
              drivers: {
                data: { id: this.servicePlanningsCarriersForm.driver_id, type: 'drivers' },
              },
            }
            // Crear FormObj
            let action = 'post'
            const formObject = {
              capacity: this.servicePlanningsCarriersForm.capacity,
              own: this.servicePlanningsCarriersForm.own,
              sct_record: this.servicePlanningsCarriersForm.sct_record,
              environmental_record: this.servicePlanningsCarriersForm.environmental_record,
              transportation_management: this.servicePlanningsCarriersForm.transportation_management,
              coupled_tag: coupleTag,
              jsonApi: {
                type: 'servicePlanningsCarriers',
                relationships: relationships,
              },
            }
            if (this.servicePlanningCarrierId !== '') {
              action = 'patch'
              formObject.jsonApi.id = this.servicePlanningCarrierId
            }
            this.$store.dispatch(`jv/${action}`, [formObject, {}])
              .then(() => {
                notification.success({
                  message: 'Registro guardado exitosamente',
                })
                this.GetCarriers()
                this.load = false
                this.CloseModal()
                // Reset vuex ids
                this.RESET_SELECTED_CATALOGS()
                // Reset Form
                this.resetCarrierForm()
              })
              .catch(() => {
                this.load = false
              })
          }
          // Fin de submit
        } else {
          return false
        }
      })
    },
    resetCarrierForm() {
      this.logModalState = 'store'
      this.servicePlanningCarrierId = ''
      this.servicePlanningsCarriersForm = {
        coupled_tags: [],
        truck_id: undefined,
        driver_id: undefined,
        service_planning_id: undefined,
        environmental_record: '',
        sct_record: '',
        transportation_management: undefined,
      }
    },
    DeleteCarrier(data) {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de eliminar este registro?',
        content: 'Esta operación es irreversible',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        onOk() {
          // Delete Object
          const deleteObject = {
            jsonApi: {
              type: 'servicePlanningsCarriers',
              id: data.jsonApi.id,
            },
          }
          me.tableCarrier.loading = true
          // Delete Object
          me.$store.dispatch('jv/delete', [deleteObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro eliminado exitosamente',
              })
              me.GetCarriers()
              me.tableCarrier.loading = false
            })
            .catch(() => {
              me.tableCarrier.loading = false
            })
        },
        onCancel() {
          notification.info({
            message: 'No se ha eliminado el registro',
          })
        },
      })
    },
    removeDomain(item) {
      const index = this.servicePlanningsCarriersForm.coupled_tags.indexOf(item)
      if (index !== -1) {
        this.servicePlanningsCarriersForm.coupled_tags.splice(index, 1)
      }
    },
    addDomain() {
      this.servicePlanningsCarriersForm.coupled_tags.push({
        value: '',
        key: Date.now(),
      })
    },
    // ServicePlanningCarriers
    // Comercial
    async GetComercial(paginationEvent = {}) {
      const params = {
        'page[number]': (Object.keys(paginationEvent).length !== 0) ? `${this.tableComercial.pagination.current}` : `${this.tableComercial.currentPageValue}`,
        'page[size]': `${this.tableComercial.perPageValue}`,
        'filter[servicePlannings]': this.servicePlanningId,
        include: 'servicePlannings,logisticsShipmentTypes',
      }
      await this.getGeneralAntTable(paginationEvent, 'spcomercialCarriers', params, this.tableComercial)
    },
    OpenComercialModal(data = {}) {
      this.getLogisticsShipmentTypes()
      this.$refs['comercial-modal'].show()
      if (Object.keys(data).length === 0) {
        // Store
        this.resetComercialForm()
      } else {
        // Update
        console.log(data)
        this.comercialID = data.jsonApi.id
        this.ComercialForm.service_planning_id = data.servicePlannings.jsonApi.id
        this.ComercialForm.logistics_shipment_type_id = data.logisticsShipmentTypes.jsonApi.id
        this.ComercialForm.capacity = data.capacity
      }
    },
    CloseComercialModal() {
      this.$refs['comercial-modal'].hide()
      this.resetComercialForm()
    },
    SendComercial() {
      this.$refs.comercialForm.validate(valid => {
        if (valid) {
          const relationships = {
            servicePlannings: {
              data: { id: this.servicePlanningId, type: 'servicePlannings' },
            },
            logisticsShipmentTypes: {
              data: { id: this.ComercialForm.logistics_shipment_type_id, type: 'logisticsShipmentTypes' },
            },
          }
          let action = 'post'
          const formObject = {
            capacity: this.ComercialForm.capacity,
            jsonApi: {
              type: 'spcomercialCarriers',
              relationships: relationships,
            },
          }
          if (this.comercialID !== '0') {
            action = 'patch'
            formObject.jsonApi.id = this.comercialID
          }
          this.load = true
          this.$store.dispatch(`jv/${action}`, [formObject, {}])
            .then(() => {
              // notification.success({
              //   message: 'Registro guardado exitosamente',
              // })
              Modal.success({
                title: '¡Guardado!',
                content: 'El registro se ha almacenado exitosamente',
                zIndex: 3000,
              })
              this.GetComercial()
              this.load = false
              this.CloseModal()
              // Reset Form
              this.CloseComercialModal()
            })
            .catch(() => {
              this.load = false
            })
        } else return false
      })
    },
    resetComercialForm() {
      this.comercialID = '0'
      this.ComercialForm = {
        service_planning_id: undefined,
        logistics_shipment_type_id: undefined,
        capacity: 0,
      }
    },
    // Comercial
    async getLogisticsShipmentTypes() {
      await this.getComboData({ sort: 'name' }, 'logisticsShipmentTypes')
      this.logisticsShipmentTypesCatalog = this.comboData
    },
    // Steps
    next() {
      this.current++
      this.setSemarnatSct()
    },
    prev() {
      this.current--
      this.setSemarnatSct()
    },
    validateLogisticForm() {
      if (this.servicePlanningsCarriersForm.coupled_tags.length !== this.getTruckID.logisticsShipmentTypes.trailers) {
        // Mostrar modal de error
        Modal.error({
          title: '¡Ha ocurrido un error!',
          content: 'Debe agregar las placas equivalentes al número total de acoplados.',
          zIndex: 3000,
        })
      }
      if (this.servicePlanningsCarriersForm.environmental_record !== '' || this.servicePlanningsCarriersForm.sct_record !== '') {
        // Mostrar modal de error
        Modal.error({
          title: '¡Ha ocurrido un error!',
          content: 'Debe agregar SCT y SEMARNAT.',
          zIndex: 3000,
        })
      }
    },
    setSemarnatSct() {
      /*
         si el wizard entra a la etapa de Detalles (SEMARNAT,SCT y Placas de Acoplados),
          y se ha seleccionado un truck
          y el modal se abrio para registrar
      */
      if (this.current === 2 && this.getTruckID !== 0 && this.logModalState === 'store') {
        // console.log('Escribir SCT y SEMARNAT')
        if (this.servicePlanningsCarriersForm.environmental_record === '') {
          this.servicePlanningsCarriersForm.environmental_record = this.getTruckID.carrierCompanies.environmental_record
        }
        if (this.servicePlanningsCarriersForm.sct_record === '') {
          this.servicePlanningsCarriersForm.sct_record = this.getTruckID.carrierCompanies.sct_record
        }
      }
    },
    // Steps
  },
  mounted() {
    this.GetComercial()
    this.servicePlanningsCarriersForm.service_planning_id = this.servicePlanningId
  },
}
</script>

<style scoped>
.border-w-3 {
  border-width:3px !important;
}
.border-w-5 {
  border-width:5px !important;
}
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.steps-action {
  margin-top: 24px;
}
</style>
