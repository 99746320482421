<template>
  <div>
    <h5 class="text-secondary font-weight-bold">Registro y Selección de Transporte</h5>
    <hr class="bg-primary">
    <p>
      Ingrese la placa de la unidad para comenzar la búsqueda &nbsp; <b-icon icon="search"/> &nbsp; <b-icon icon="arrow-down" animation="cylon-vertical"/>
    </p>
    <small class="text-secondary">
     Si la placa no coincide con nuestros registros, deberá registrar la unidad agregando el tipo logístico de transporte correspondiente
    </small>

    <!-- Barra de Búsqueda -->
    <search-trucks-bar @getData="getData"
                        :sortByOptions="sortByOptions"
                        @searchValue="searchValue = $event"
                       @companyValue="companyValue = $event"
                        @sortByValue="sortByValue = $event"
                        @sortDirectionValue="sortDirectionValue = $event"
                        @perPageValue="perPageValue = $event"
                        @currentPageValue="currentPageValue = $event"
    />

    <!--Botonera  -->
    <div class="row justify-content-center mb-2">
      <div class="col-12 text-right">
        <b-button @click="OpenModal('store')" pill  variant="primary" >
          <b-icon-plus/> Nuevo
        </b-button>
      </div>
    </div>
    <!-- Modal -->
    <modal-Content :modalShow="modalForm" @Close="CloseModal" @getData="getData" :resourceName="resourceName" :modalData="modalData" />
    <!-- Tabla -->
    <detail-catalog-table :tableObject="tableObject" :typeTable="typeTable"
                          @Modal="OpenModal" @Filter="getData" @Select="SelectedTruck"
                          @searchInput="searchInput = $event"
    >

      <div slot="details" slot-scope="record">
        <small v-if="record.record.carrierCompanies.name">
          <strong>Empresa Transportista: </strong>{{record.record.carrierCompanies.name}}
        </small>
        <small  v-if="record.record.logisticsShipmentTypes.name">
          <br>
          <strong>Tipos Logísticos de Transporte: </strong>{{record.record.logisticsShipmentTypes.name}}
        </small>
      </div>
    </detail-catalog-table>
  </div>
</template>

<script>
import { objectArray, resolvePagination } from '@/helpers/jsonApiTransformer'
import DetailCatalogTable from '../../components/table'
import searchTrucksBar from './searchTrucksBar'
import modalContent from './modalContent'
import { mapActions } from 'vuex'
export default {
  name: 'truckForm',
  props: {
    typeTable: {
      default: 'catalogs',
      type: String,
    },
  },
  components: {
    DetailCatalogTable,
    searchTrucksBar,
    modalContent,
  },
  data() {
    return {
      // <!-- Constantes -->
      resource: 'trucks',
      resourceName: 'Camiones',
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'catalogs',
          breadcrumbName: 'Catálogos',
          aIcon: '',
        },
        {
          breadcrumbName: 'Camiones',
          aIcon: '',
        },
      ],
      searchInput: null,
      // <!-- Table -->
      tableObject: {
        data: [],
        columns: [
          {
            title: 'Placa',
            dataIndex: 'tag',
            key: 'tag',
            class: 'small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Capacidad Máxima',
            dataIndex: 'maximum_capacity',
            key: 'maximum_capacity',
            class: 'small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Detalles',
            dataIndex: 'detail',
            key: 'detail',
            class: 'small',
            scopedSlots: {
              customRender: 'myCustomColumns',
            },
          },
          {
            title: 'Acción',
            dataIndex: '',
            class: 'text-center small',
            key: 'x',
            fixed: 'right',
            scopedSlots: {
              customRender: 'action',
            },
          }],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 30,
          lastPage: 0,
        },
      },
      // <!-- Table -->
      // <!-- Barra de Control -->
      searchValue: '',
      companyValue: '',
      sortByValue: '',
      sortByDefaultValue: '',
      sortDirectionValue: '',
      sortByOptions: [
        { value: '', text: 'Ordenar por', disabled: true },
        { value: 'tag', text: 'Placa' },
      ],
      perPageValue: 15,
      currentPageValue: 1,
      // <!-- Barra de Control -->

      // <!-- Constantes -->
      // <!-- Form with Model -->
      modalForm: false,
      modalData: {},
    }
  },
  computed: {
    sortDirection() {
      if (this.sortDirectionValue === '') {
        return ''
      } else if (this.sortDirectionValue === '-') {
        return '-'
      } else {
        return ''
      }
    },
    sortValue() {
      if (this.sortByValue === '') {
        return this.sortByDefaultValue
      } else {
        return this.sortByValue
      }
    },
  },
  methods: {
    ...mapActions('servicePlannings', ['SET_SELECTED_CATALOGS']),
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    // <!-- Get,Paginate,Search & Filter Data  -->
    getData(paginationEvent = {}) {
      if (Object.keys(paginationEvent).length !== 0) {
        this.tableObject.pagination.current = paginationEvent.current
        this.tableObject.pagination.pageSize = paginationEvent.pageSize
      }
      const params = {
        'page[number]': (Object.keys(paginationEvent).length !== 0) ? `${this.tableObject.pagination.current}` : `${this.currentPageValue}`,
        'page[size]': `${this.perPageValue}`,
        // sort: `${this.sortDirection}${this.sortValue}`,
        include: 'carrierCompanies,logisticsShipmentTypes',
      }
      if (this.searchValue !== '') params['filter[global]'] = `${this.searchValue}`
      if (this.companyValue !== '') params['filter[carrierCompanies]'] = this.companyValue

      this.tableObject.loading = true
      this.$store.dispatch('jv/get', [this.resource, {
        params: params,
      }])
        .then(response => {
          this.tableObject.loading = false
          if (response.jsonApi.json.data.length === 0) {
            this.prefabricateForm(this.searchValue)
          }
          const jsonApiPagination = response.jsonApi.json.meta.page
          const tableData = response
          this.tableObject.data = objectArray(tableData)
          this.tableObject.pagination = resolvePagination(jsonApiPagination)
        })
    },
    // <!-- Get,Paginate,Search & Filter Data  -->
    // <!-- Get array selects data  -->
    getCarrier_companies() {
      this.$store.dispatch('jv/get', ['carrierCompanies', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.carrier_companies = objectArray(tableData)
        })
    },
    // <!-- Get array selects data  -->
    // <!-- Open & Close Modal Logic  -->
    prefabricateForm(value) {
      this.modalForm = true
      this.modalData = value
    },
    OpenModal(action, data = {}) {
      this.modalForm = true
      this.modalData = data
    },
    CloseModal() {
      this.modalForm = false
    },
    // <!-- Open & Close Modal Logic  -->
    resetForm() {
      this.$refs['rule-Form'].resetFields()
    },
    SelectedTruck(data) {
      this.SET_SELECTED_CATALOGS({
        resource: this.resource, data,
      })
    },
  },
  mounted() {

  },
}
</script>

<style scoped>
  .custom-filter-dropdown {
    padding: 8px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  }

  .highlight {
    background-color: rgb(255, 192, 105);
    padding: 0px;
  }
</style>
