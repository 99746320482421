import { objectArray } from '@/helpers/jsonApiTransformer'
export const catalogMixin = {
  data() {
    return {
      // Constantes
      yearsCatalog: [
        { value: 2020, text: '2020' },
        { value: 2021, text: '2021' },
        { value: 2022, text: '2022' },
        { value: 2023, text: '2023' },
        { value: 2024, text: '2024' },
        { value: 2025, text: '2025' },
        { value: 2026, text: '2026' },
        { value: 2027, text: '2027' },
        { value: 2028, text: '2028' },
        { value: 2029, text: '2029' },
        { value: 2030, text: '2030' },
        { value: 2031, text: '2031' },
        { value: 2032, text: '2032' },
        { value: 2033, text: '2033' },
        { value: 2034, text: '2034' },
        { value: 2035, text: '2035' },
        { value: 2036, text: '2036' },
        { value: 2037, text: '2037' },
        { value: 2038, text: '2038' },
        { value: 2039, text: '2039' },
        { value: 2040, text: '2040' },
      ],
      monthsCatalog: [
        { value: 1, text: 'Enero' },
        { value: 2, text: 'Febrero' },
        { value: 3, text: 'Marzo' },
        { value: 4, text: 'Abril' },
        { value: 5, text: 'Mayo' },
        { value: 6, text: 'Junio' },
        { value: 7, text: 'Julio' },
        { value: 8, text: 'Agosto' },
        { value: 9, text: 'Septiembre' },
        { value: 10, text: 'Octubre' },
        { value: 11, text: 'Noviembre' },
        { value: 12, text: 'Diciembre' },
      ],
      // Enums
      // Catalogos
      countryCatalog: [],
      stateCatalog: [],
      currentsCatalog: [],
      serviceTypesCatalog: [],
      transactionTypesCatalog: [],
      clientsCatalog: [],
      workCenterCatalog: [],
      serviceEngineersCatalog: [],
      logisticsShipmentTypesCatalog: [],
      servicePlanningStatus: ['Pendiente', 'En Plataforma', 'Concluido', 'Con Incidencia', 'Reprogramación', 'Rechazado'],
      serviceSap_status: ['Pendiente', 'Programado', 'Rechazado'],
      serviceStatus: ['Programado', 'No Programado'],
    }
  },
  methods: {
    getCountriesCatalog() {
      this.$store.dispatch('jv/get', ['countries', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.countryCatalog = objectArray(tableData)
        })
    },
    getStatesCatalog() {
      this.$store.dispatch('jv/get', ['states', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.stateCatalog = objectArray(tableData)
        })
    },
    getCurrentsCatalog() {
      this.$store.dispatch('jv/get', ['currents', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.currentsCatalog = objectArray(tableData)
        })
    },
    getWorkCenterCatalog() {
      this.$store.dispatch('jv/get', ['workCenters', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.workCenterCatalog = objectArray(tableData)
        })
    },
    getServiceTypesCatalog() {
      this.$store.dispatch('jv/get', ['serviceTypes', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.serviceTypesCatalog = objectArray(tableData)
        })
    },
    getTransactionTypesCatalog() {
      this.$store.dispatch('jv/get', ['transactionTypes', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.transactionTypesCatalog = objectArray(tableData)
        })
    },
    getClientsCatalog() {
      this.$store.dispatch('jv/get', ['clients', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.clientsCatalog = objectArray(tableData)
        })
    },
    getServiceEngineersCatalog() {
      // ToDo Agregar filtro para consultar solo ings de servicio
      this.$store.dispatch('jv/get', ['users', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.serviceEngineersCatalog = objectArray(tableData)
        })
    },
    getLogisticsShipmentTypesCatalog(params = { sort: 'name' }) {
      this.$store.dispatch('jv/get', ['logisticsShipmentTypes', {
        params: params,
      }])
        .then(response => {
          const tableData = response
          this.generalResponse = objectArray(tableData)
        })
    },
    getLogisticsShipmentType(id) {
      this.$store.dispatch('jv/get', [`logisticsShipmentTypes/${id}`, {}])
        .then(response => {
          return (response.trailers)
        })
    },
  },
}
