<template>
  <div>
    <h5 class="text-secondary font-weight-bold">Registro y Selección de Conductor</h5>
    <hr class="bg-primary">
    <p>
      Ingrese el nombre del conductor para comenzar la búsqueda &nbsp; <b-icon icon="search"/> &nbsp; <b-icon icon="arrow-down" animation="cylon-vertical"/>
    </p>
    <small class="text-secondary">
      Si el nombre del conductor no coincide con nuestros registros, deberá registrarlo
    </small>
    <!-- Barra de Búsqueda -->
    <search-drivers-bar @getData="getData"
                       :sortByOptions="sortByOptions"
                       @searchValue="searchValue = $event"
                       @companyValue="companyValue = $event"
                       @sortByValue="sortByValue = $event"
                       @sortDirectionValue="sortDirectionValue = $event"
                       @perPageValue="perPageValue = $event"
                       @currentPageValue="currentPageValue = $event"
    />

    <!--Botonera  -->
    <div class="row justify-content-center">
      <div class="col-12 text-right">
        <b-button @click="OpenModal('store')" pill  variant="primary" >
          <b-icon-plus/> Nuevo
        </b-button>
      </div>
    </div>
    <!-- Modal -->

    <modal-Content :modalShow="modalForm" @Close="CloseModal" @getData="getData" :resourceName="resourceName" :modalData="modalData"/>

    <!-- Tabla -->
    <detail-catalog-table :tableObject="tableObject" :typeTable="typeTable"
                          @Modal="OpenModal" @Filter="getData" @Select="SelectedDriver"
                          @searchInput="searchInput = $event"
    >

      <div slot="details" slot-scope="record">
        <small v-if="record.record.carrierCompanies.name">
          <strong>Empresa Transportista: </strong>{{record.record.carrierCompanies.name}}
        </small>
      </div>
    </detail-catalog-table>
  </div>
</template>

<script>
import { objectArray, resolvePagination } from '@/helpers/jsonApiTransformer'
import modalContent from './modalContent'
import DetailCatalogTable from '../../components/table'
import searchDriversBar from './searchDriversBar'
import { mapActions } from 'vuex'
export default {
  name: 'driverForm',
  props: {
    typeTable: {
      default: 'catalogs',
      type: String,
    },
  },
  components: {
    DetailCatalogTable,
    searchDriversBar,
    modalContent,
  },
  data() {
    return {
      // <!-- Constantes -->
      resource: 'drivers',
      resourceName: 'Conductores',
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'catalogs',
          breadcrumbName: 'Catálogos',
          aIcon: '',
        },
        {
          breadcrumbName: 'Conductores',
          aIcon: '',
        },
      ],
      searchInput: null,
      // <!-- Table -->
      tableObject: {
        data: [],
        columns: [
          {
            title: 'Clave de Conductor',
            dataIndex: 'code',
            key: 'code',
            class: 'small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.code.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Nombre del Conductor',
            dataIndex: 'name',
            key: 'name',
            class: 'small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Folio de Licencia',
            dataIndex: 'license_folio',
            key: 'license_folio',
            class: 'small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.license_folio.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Fecha de Vencimiento',
            dataIndex: 'expired_at',
            key: 'expired_at',
            class: 'small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.expired_at.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Correo Electrónico',
            dataIndex: 'email',
            key: 'email',
            class: 'small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.email.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Teléfono',
            dataIndex: 'phone',
            key: 'phone',
            class: 'small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.phone.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Dirección',
            dataIndex: 'address',
            key: 'address',
            class: 'small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.address.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Detalles',
            dataIndex: 'detail',
            key: 'detail',
            class: 'small',
            scopedSlots: {
              customRender: 'myCustomColumns',
            },
          },
          {
            title: 'Acción',
            dataIndex: '',
            class: 'text-center small',
            key: 'x',
            fixed: 'right',
            scopedSlots: {
              customRender: 'action',
            },
          }],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 30,
          lastPage: 0,
        },
      },
      // <!-- Table -->
      // <!-- Barra de Control -->
      searchValue: '',
      companyValue: '',
      sortByValue: '',
      sortByDefaultValue: 'name',
      sortDirectionValue: '',
      sortByOptions: [
        { value: '', text: 'Ordenar por', disabled: true },
        { value: 'code', text: 'Clave del Conductor' },
        { value: 'name', text: 'Nombre del Conductor' },
      ],
      perPageValue: 15,
      currentPageValue: 1,
      // <!-- Barra de Control -->
      // <!-- Constantes -->
      // // <!-- Form with Model -->
      modalForm: false,
      modalData: {},
    }
  },
  computed: {
    sortDirection() {
      if (this.sortDirectionValue === '') {
        return ''
      } else if (this.sortDirectionValue === '-') {
        return '-'
      } else {
        return ''
      }
    },
    sortValue() {
      if (this.sortByValue === '') {
        return this.sortByDefaultValue
      } else {
        return this.sortByValue
      }
    },
  },
  methods: {
    ...mapActions('servicePlannings', ['SET_SELECTED_CATALOGS']),
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    // <!-- Get,Paginate,Search & Filter Data  -->
    getData(paginationEvent = {}) {
      if (Object.keys(paginationEvent).length !== 0) {
        this.tableObject.pagination.current = paginationEvent.current
        this.tableObject.pagination.pageSize = paginationEvent.pageSize
      }
      const params = {
        'page[number]': (Object.keys(paginationEvent).length !== 0) ? `${this.tableObject.pagination.current}` : `${this.currentPageValue}`,
        'page[size]': `${this.perPageValue}`,
        sort: `${this.sortDirection}${this.sortValue}`,
        include: 'carrierCompanies',
      }
      if (this.searchValue !== '') params['filter[global]'] = `${this.searchValue}`
      if (this.companyValue !== '') params['filter[carrierCompanies]'] = this.companyValue

      this.tableObject.loading = true
      this.$store.dispatch('jv/get', [this.resource, {
        params: params,
      }])
        .then(response => {
          this.tableObject.loading = false
          if (response.jsonApi.json.data.length === 0) {
            this.prefabricateForm(this.searchValue)
          }
          const jsonApiPagination = response.jsonApi.json.meta.page
          const tableData = response
          this.tableObject.data = objectArray(tableData)
          this.tableObject.pagination = resolvePagination(jsonApiPagination)
        })
    },
    // <!-- Get,Paginate,Search & Filter Data  -->
    // <!-- Open & Close Modal Logic  -->
    prefabricateForm(value) {
      this.modalForm = true
      this.modalData = value
    },
    OpenModal(action, data = {}) {
      this.modalForm = true
      this.modalData = data
    },
    CloseModal() {
      this.modalForm = false
    },
    // <!-- Open & Close Modal Logic  -->
    resetForm() {
      this.$refs['rule-Form'].resetFields()
    },
    SelectedDriver(data) {
      this.SET_SELECTED_CATALOGS({
        resource: this.resource, data,
      })
    },
  },
}
</script>

<style scoped>
  .custom-filter-dropdown {
    padding: 8px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  }

  .highlight {
    background-color: rgb(255, 192, 105);
    padding: 0px;
  }
</style>
